.boardContainer {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-right: 10%;
  margin-left: 10%;
  box-shadow: 0px 4px 14px #e7e7e7;
  display: flex;
  padding: 2rem;
  gap: 2rem;
}
.infoContainer {
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.img {
  width: 50%;
  height: 50%;
  align-self: center;
}

.button {
  margin-top: 9rem;
  width: 13rem;
}
