.link:hover {
  cursor: pointer;
  color: #fac70d;
}

.title {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.title:hover {
  cursor: pointer;
  color: #fac70d;
  fill: #fac70d;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.title:hover .icon {
  cursor: pointer;
  color: #fac70d;
  fill: #fac70d;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.title:hover .link {
  cursor: pointer;
  color: #fac70d;
  fill: #fac70d;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.icon {
  fill: black;
  height: 2.5rem;
  width: 2.5rem;
}

.deadLink {
  color: lightgrey;
}

.deadLink:hover {
  color: lightgrey;
  cursor: default;
}
