.header {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #151d44;
  align-items: center;
  justify-content: flex-start;
}

.left {
  width: 25%;
  display: flex;
  align-items: center;
  gap: 1rem;
  display: flex;
  justify-content: flex-start;
}

.center {
  margin-right: 5%;
  margin-left: 5%;
  width: 40%;
  align-self: center;
}

.right {
  width: 25%;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}

.loginButton {
  cursor: pointer;
  background-color: #fac70d;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  display: flex;
  position: relative;
  border: 2px solid #151d44;
}

.loginButton:active {
  background-color: #151d44;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  display: flex;
  position: relative;
  border: 2px solid #fac70d;
}

.loginIcon {
  cursor: pointer;
  color: #151d44;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loginIcon:active {
  color: #fac70d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.allen {
  cursor: pointer;
  color: #fac70d;
}
.allen:active {
  color: white;
}

.oiLogo {
  height: 2rem;
  width: 2rem;
  fill: #fac70d;
}

.oiLogo:hover {
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  fill: white;
}

.menu {
  cursor: pointer;
  color: white;
  min-width: 1rem;
  min-height: 1rem;
}
.menu:active {
  color: #fac70d;
}

.OI {
  color: #fac70d;
  white-space: nowrap;
  font-size: 1vw;
  font-weight: bold;
  cursor: pointer;
}

.OI:hover {
  color: white;
  white-space: nowrap;
  font-size: 1vw;
  font-weight: bold;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #111;
  background-color: rgba(17, 17, 17, 0.15);
}

.search {
  width: 100%;
  justify-self: center;
  z-index: 3;
}

.searchBg {
  width: 40%;
  position: absolute;
  justify-self: center;
  z-index: 2;
  background-color: white;
  border-radius: 8px;
  margin-top: -0.5rem;
  padding-bottom: 2rem;
  padding-top: 5rem;
  padding-left: 3rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 1rem;
}

.container {
  display: flex;
  justify-content: center;
}

.searchCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2rem;
  width: 100%;
  gap: 1rem;
  cursor: pointer;
}

.loginWrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.loginWrapper:hover {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.login {
  color: #fac70d;
  animation: blinker 4s linear infinite;
}

@keyframes blinker {
  50% {
    color: white;
  }
}
