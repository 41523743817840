.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 4rem;
  margin-right: 4rem;
  gap: 2rem;
  border-bottom: 1px solid rgb(229, 229, 229);
}

.alt {
  color: black;
  cursor: pointer;
}

.alt:active {
  color: #fac70d;
  cursor: pointer;
}
