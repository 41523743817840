.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 4rem;
  margin-right: 4rem;
  gap: 2rem;
  border-bottom: 1px solid grey;
}

.alt {
  color: black;
}

.container {
  display: flex;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 6rem;
}

.logo {
  align-self: center;
  width: 20rem;
  height: 25rem;
  margin-left: 10%;
  margin-right: 10%;
}

.card {
  height: 25rem;
  width: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  box-shadow: 0px 4px 14px #e7e7e7;
  overflow-y: hidden;
}

.cardLabel {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 0.5rem;
}

.jobContainer {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.button {
  margin-top: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
}

.job {
  cursor: pointer;
}
